<template>
  <v-menu
    v-model="open"
    bottom
    offset-y
    :close-on-content-click="false"
    :color="theme.website.shop.menu.color"
    :dark="theme.website.shop.menu.dark"
    :light="theme.website.shop.menu.light"
  >
    <template v-slot:activator="{on}">
      <slot v-if="hasActivator" name="activator" :on="on" />
      <v-btn v-else icon v-on="on"><v-icon>mdi-filter</v-icon></v-btn>
    </template>
    <v-list>
      <v-list-item>
        <v-list-item-content class="px-1">
          <product-categories-filter v-model="internalValue.categories" @input="onValueChanged" />
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content class="px-1">
          <v-select
            v-model="internalValue.sort"
            dense
            solo
            hide-details
            :label="$t('components.website.shopFilter.sort')"
            :items="sortItems"
            clearable
            @input="$emit('input', internalValue)"
          />
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content class="px-1">
          <v-btn
            :loading="loading"
            :color="theme.website.shop.filter.applyColor"
            @click="$emit('input', internalValue)"
          >{{ $t('components.website.shopFilter.apply') }}</v-btn>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
  import ProductCategoriesFilter from './ProductCategoriesFilter.vue'
  import Themeable from '@peynman/press-vue-core/mixins/Themeable'

  export default {
    name: 'ShopFiltersMenu',
    components: {
      ProductCategoriesFilter,
    },
    mixins: [
      Themeable,
    ],
    props: {
      loading: Boolean,
      value: Object,
    },
    data: vm => ({
      internalValue: vm.value ?? { categories: [], sort: null },
      sortItems: Object.keys(vm.$t('components.website.shopFilter.sortOptions')).map(k => ({
        text: vm.$t('components.website.shopFilter.sortOptions.' + k),
        value: k,
      })),
      open: false,
    }),
    computed: {
      hasActivator () {
        return !!this.$slots.activator || !!this.$scopedSlots.activator
      },
    },
    watch: {
      value () {
        this.internalValue = this.value ?? { categories: [], sort: null }
      },
    },
    methods: {
      onValueChanged () {
        this.$emit('input', this.internalValue)
        if (this.$vuetify.breakpoint.xs) {
          this.open = false
        }
      },
    },
  }
</script>
