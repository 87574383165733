<template>
  <v-toolbar
    :color="theme.website.shop.filter.color"
    :dark="theme.website.shop.filter.dark"
    :light="theme.website.shop.filter.light"
    :flat="theme.website.shop.filter.flat"
    :tile="theme.website.shop.filter.tile"
    :height="$dim(theme.website.shop.filter.height)"
  >
    <v-breadcrumbs
      :class="`${$vuetify.breakpoint.xs ? 'px-0':''}`"
      :items="items"
    >
      <template v-slot:divider>
        <v-icon>mdi-chevron-left</v-icon>
      </template>
      <template v-slot:item="{ item }">
        <v-btn
          dense
          text
          small
          class="pa-0 ma-0"
          :x-small="$vuetify.breakpoint.xs"
          :to="item.to"
          :href="item.href"
        >
          <span>{{ item.text }}</span>
        </v-btn>
      </template>
    </v-breadcrumbs>
    <div v-if="!isSingleCategory" />
  </v-toolbar>
</template>

<script>
  import Themeable from '@peynman/press-vue-core/mixins/Themeable'
  import { mapGetters } from 'vuex'
  import ProductCategories from '@peynman/press-vue-core/mixins/ProductCategories'

  export default {
    name: 'WebsiteBreadcrumbs',
    mixins: [
      Themeable,
      ProductCategories,
    ],
    props: {
      filters: Object,
      product: Object,
    },
    data: vm => ({
      categories: [],
    }),
    computed: {
      ...mapGetters('repos', [
        'isProductCategoriesLoading',
      ]),
      normalizedCategories () {
        if (this.product?.categories) {
          return this.product.categories.map(s => Object.assign({}, s))
            .sort((a, b) => (parseInt(b.data?.order) ?? b.id) - (parseInt(a.data?.order) ?? a.id))
            .filter((a, i) => i === 0)
            .map(a => a.id)
        }

        return Object.keys(this.filters?.categories ?? {})
          .filter(k => this.filters?.categories[k])
          .map(k => k)
      },
      isSingleCategory () {
        return this.normalizedCategories?.length === 1
      },
      currentCategoryId () {
        return parseInt(this.normalizedCategories?.[0] ?? 0)
      },
      currentCategory () {
        return this.categories?.find(c => c.id === this.currentCategoryId)
      },
      categoryHierarchy () {
        if (this.currentCategory?.parent_id > 0) {
          const flatten = (hierarchy, cc) => {
            const parent = this.categories?.find(c => c.id === cc.parent_id)
            if (parent) {
              hierarchy.unshift({
                text: parent.data.title,
                to: `/shop/category/${parent.id}`,
              })
              if (parent.parent_id) {
                hierarchy.unshift(...flatten([], parent))
              }
            }
            return hierarchy
          }
          return flatten([], this.currentCategory)
        }
        return []
      },
      items () {
        return [
          {
            text: this.$t('components.website.breadcrumbs.home'),
            href: '/',
          },
          {
            text: this.$t('components.website.breadcrumbs.shop'),
            href: '/shop',
          },
          ...this.categoryHierarchy,
          ...(this.isSingleCategory ? [{
            text: this.currentCategory?.data?.title ?? this.currentCategoryId,
            href: `/shop/category/${this.currentCategoryId}`,
          }] : []),
        ]
      },
    },
    mounted () {
      return this.$store.dispatch('repos/fetchProductCategories').then(categories => {
        function flatten (arr) {
          return arr?.reduce((f, c) => {
            f.push(c)
            f.push(...flatten(c.children))
            return f
          }, []) ?? []
        }
        this.categories = flatten(categories)
        this.$forceUpdate()
      })
    },
  }
</script>
