<template>
  <v-toolbar
    v-bind="theme.website.shop.filter"
    :color="theme.website.shop.filter.color"
    :dark="theme.website.shop.filter.dark"
    :light="theme.website.shop.filter.light"
    :height="$dim(theme.website.shop.filter.height)"
    :flat="theme.website.shop.filter.flat"
    :dense="theme.website.shop.filter.dense"
    :tile="theme.website.shop.filter.tile"
  >
    <div v-if="!$vuetify.breakpoint.xs" class="d-flex flex-row flex-grow-1 justify-center align-center">
      <product-categories-filter v-model="internalValue.categories" @input="$emit('input', internalValue)" />
      <v-col cols="4">
        <v-select
          v-model="internalValue.sort"
          solo
          dense
          hide-details
          :label="$t('components.website.shopFilter.sort')"
          :items="sortItems"
          clearable
          @input="$emit('input', internalValue)"
        />
      </v-col>
      <v-btn
        :loading="loading"
        :color="theme.website.shop.filter.applyColor"
        @click="$emit('input', internalValue)"
      >{{ $t('components.website.shopFilter.apply') }}</v-btn>
    </div>
    <!-- Mobile filters menu -->
    <div v-else class="d-flex flex-grow-1 justify-center align-center">
      <shop-filters-menu
        v-model="internalValue"
        :loading="loading"
        @input="$emit('input', internalValue)"
      >
        <template v-slot:activator="{on}">
          <v-btn block :color="theme.website.shop.filter.menuBtnColor" v-on="on">
            <v-icon class="me-2">mdi-filter</v-icon>
            <span>{{ $t('components.website.shopFilter.title') }}</span>
          </v-btn>
        </template>
      </shop-filters-menu>
    </div>
  </v-toolbar>
</template>

<script>
  import Themeable from '@peynman/press-vue-core/mixins/Themeable'
  import ProductCategoriesFilter from './ProductCategoriesFilter.vue'
  import ShopFiltersMenu from './ShopFiltersMenu.vue'

  export default {
    name: 'ShopFilter',
    components: {
      ProductCategoriesFilter,
      ShopFiltersMenu,
    },
    mixins: [
      Themeable,
    ],
    props: {
      loading: Boolean,
      value: Object,
    },
    data: vm => ({
      internalValue: vm.value ?? { categories: [], sort: null },
      sortItems: Object.keys(vm.$t('components.website.shopFilter.sortOptions')).map(k => ({
        text: vm.$t('components.website.shopFilter.sortOptions.' + k),
        value: k,
      })),
    }),
    watch: {
      value () {
        this.internalValue = this.value ?? { categories: [], sort: null }
      },
    },
  }
</script>
