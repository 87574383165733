<template>
  <website>
    <template slot="toolbar">
      <website-breadcrumbs :filters="filters" />
      <shop-filter v-model="filters" :loading="loading" @input="onUpdate" />
    </template>
    <products-masonry :products="products" :loading="loading" />
    <v-pagination
      v-if="total > 0"
      :value="internalPage"
      class="mt-2"
      color="blue-grey darken-3"
      :loading="loading"
      :disabled="loading"
      :length="totalPages"
      @input="onChangePage"
    />
  </website>
</template>

<script>
  import ProductList from '@peynman/press-vue-core/mixins/ProductList'
  import ShopFilter from '../components/Shop/ShopFilter.vue'
  import ProductsMasonry from '../components/Product/ProductsMasonry.vue'
  import Website from '../templates/Website/Website.vue'
  import WebsiteBreadcrumbs from '../components/Breadcrumbs/WebsiteBreadcrumbs.vue'
  import Animation from '../Animation'

  export default {
    name: 'ModenaShop',
    components: {
      Website,
      ShopFilter,
      ProductsMasonry,
      WebsiteBreadcrumbs,
    },
    mixins: [ProductList],
    props: {
      pagedPathPrefix: {
        type: String,
        default: '/',
      },
      limit: {
        type: Number,
        default: 30,
      },
      filters: {
        type: Object,
        default: () => ({
          sort: 'publish_at_desc',
        }),
      },
    },
    data: vm => ({
      scrollTimeStart: 0,
    }),
    watch: {
      page () {
        this.$nextTick(() => {
          document.body.scrollTop = 0 // For Safari
          document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
        })
      },
    },
    created () {
      window.document.title = this.$t('pagesTitles.shop')
    },
    mounted () {
      this.updateProductsList().finally(() => {
        this.updateScrollPosition()
      })
    },
    methods: {
      updateScrollPosition () {
        if (window.savedScrollPosition) {
          this.scrollToYPosition(window.savedScrollPosition.y)
          window.savedScrollPosition = null
        }
      },
      scrollToYPosition (pos) {
        const scrollStart = window.scrollY
        Animation.easeInOutQuad(1000, (time, value, end) => {
          window.scrollTo(0, scrollStart + (pos - scrollStart) * value)
        }).then(() => {
          window.scrollTo(0, pos)
        })
      },
      onUpdate () {
        this.internalPage = 1
        this.updateProductsList().finally(() => {
          this.updateScrollPosition()
        })
      },
      onChangePage (page) {
        this.$router.push(this.pagedPathPrefix + page)
      },
    },
  }
</script>
