<template>
  <product-categories-filter-input
    v-model="internalValue"
    :color="theme.website.shop.menu.color"
    :dark="theme.website.shop.menu.dark"
    :light="theme.website.shop.menu.light"
    :filter-function="c => !!c.data.showInFrontFilters"
    :is-filterable="c => !!c.data.isFilterable"
    :is-single-select="$vuetify.breakpoint.xs"
  >
    <template v-slot:activator="{on}">
      <v-btn :color="theme.website.shop.menu.activatorColor" v-on="on">{{ $t('components.website.shopFilter.categoriesFilter') }}</v-btn>
    </template>
    <v-divider />
    <v-list-item>
      <v-list-item-content>
        <v-btn text @click="resetFilters">{{ $t('components.website.shopFilter.removeFilters') }}</v-btn>
      </v-list-item-content>
    </v-list-item>
  </product-categories-filter-input>
</template>

<script>
  import Themeable from '@peynman/press-vue-core/mixins/Themeable'
  import ProductCategoriesFilterInput from '@peynman/press-vue-admin/components/Inputs/ProductCategoriesInput/ProductCategoriesFilterInput.vue'

  export default {
    name: 'ProductCategoriesFilter',
    components: {
      ProductCategoriesFilterInput,
    },
    mixins: [
      Themeable,
    ],
    props: {
      title: String,
      value: Object,
    },
    data: vm => ({
      internalValue: vm.value ?? {},
    }),
    watch: {
      value () {
        this.internalValue = this.value ?? {}
      },
      internalValue: {
        deep: true,
        handler () {
          this.$emit('input', this.internalValue)
        },
      },
    },
    methods: {
      resetFilters () {
        this.internalValue = {}
      },
    },
  }
</script>
