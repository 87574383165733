export const EaseFunctions = {
  easeInOutCubic (x) {
    return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2
  },
  easeInOutQuad (x) {
    return x < 0.5 ? 2 * x * x : 1 - Math.pow(-2 * x + 2, 2) / 2
  },
}

export default {
  easeInOutQuad: (time, callback, tick = 30) => {
    return new Promise((resolve, reject) => {
      const startTime = Date.now()
      const timerfunc = function (startTime, endref) {
        const now = Date.now()
        const delta = now - startTime
        const value = EaseFunctions.easeInOutQuad(Math.min(parseFloat(delta) / parseFloat(time), 1))
        callback(delta, value, () => {
          window.clearInterval(endref)
          resolve()
        })
        if (value === 1) {
          window.clearInterval(endref)
          resolve()
        }
      }
      const timerref = window.setInterval(() => {
        timerfunc(startTime, timerref)
      }, tick)
    })
  },
}
